'use client';

import React from 'react';

import Link from 'next/link';

import { TERM_CATEGORY } from '@/api';
import Instagram from '@/assets/svgs/instagram.svg';
import Logo from '@/assets/svgs/logo.svg';
import NaverBlog from '@/assets/svgs/naver_blog.svg';
import Youtube from '@/assets/svgs/youtube.svg';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useDeviceInfoStore } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './MainFooter.module.scss';

const UPDATE_LINK =
  'https://welfarehello.notion.site/60cbc183e45b4256afcc18f450d7ca7e?pvs=74';

export const MainFooter = () => {
  const appVersion = useDeviceInfoStore((state) => state.appVersion);
  const setTermsDrawerType = useGlobalStore(
    (state) => state.setTermsDrawerType,
  );

  let devCount = 0;

  const router = useCustomRouter();

  return (
    <footer className={styles['footer']}>
      <Logo className={styles.logo} />
      <ul className={styles['page-list']}>
        <li>
          <Link href={ROUTES.NOTICE}>공지사항</Link>
        </li>
        <li>
          <a href={UPDATE_LINK} rel="noreferrer" target="_blank">
            업데이트 노트
          </a>
        </li>
        <li>
          <Link href={ROUTES.INQUIRY_LIST}>1:1 문의</Link>
        </li>
      </ul>
      <ul className={styles['info-list']}>
        <li
          onClick={() => {
            devCount++;
            if (devCount === 10) {
              router.push(ROUTES.TEST.pathname);
            }
          }}
        >
          주식회사 웰로
          <div />
          대표 김유리안나
        </li>
        <li>
          <span>사업자등록번호:</span> 705-86-02126
        </li>
        <li>
          <address>
            서울특별시 관악구 남부순환로 1933, 8층 (봉천동) <div />
          </address>
        </li>
        <li>
          <a href="mailto:cs@wello.info">cs@wello.info</a> <div />
          <a href="tel:02-889-9369">02-889-9369</a>
        </li>
      </ul>
      <ul className={styles['link-list']}>
        <li>
          <a
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7058602126"
            rel="noreferrer"
            target="_blank"
          >
            사업자정보확인
          </a>
        </li>
        <li>
          <button
            type="button"
            onClick={() => setTermsDrawerType(TERM_CATEGORY.SERVICE_TERMS)}
          >
            이용약관
          </button>
        </li>
        <li>
          <button
            className={styles['privacy-policy']}
            type="button"
            onClick={() => setTermsDrawerType(TERM_CATEGORY.PRIVACY_POLICY)}
          >
            개인정보처리방침
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => setTermsDrawerType(TERM_CATEGORY.MARKETING_TERMS)}
          >
            마케팅이용동의
          </button>
        </li>
      </ul>
      <nav className={styles['footer-social']}>
        <a
          aria-label="인스타그램에서 웰로 팔로우하기"
          className={styles['social-instagram']}
          href="https://www.instagram.com/wello_welfarehello"
          rel="noreferrer"
          target="_blank"
        >
          <Instagram className={styles['social-icon']} />
        </a>
        <a
          aria-label="유튜브에서 웰로 구독하기"
          className={styles['social-youtube']}
          href="https://www.youtube.com/@wello_welfarehello"
          rel="noreferrer"
          target="_blank"
        >
          <Youtube className={styles['social-icon']} />
        </a>
        <a
          aria-label="네이버 블로그에서 웰로 방문하기"
          className={styles['social-blog']}
          href="https://blog.naver.com/wello_official"
          rel="noreferrer"
          target="_blank"
        >
          <NaverBlog className={styles['social-icon']} />
        </a>
      </nav>

      <strong className={styles.inc}>
        ⓒ Wello Inc.
        <div className={styles.version}>{appVersion}</div>
      </strong>
    </footer>
  );
};
