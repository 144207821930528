'use client';

import type { PropsWithChildren } from 'react';

import { PageNavigation } from '@/components/client';
import { useCheckWelloApp } from '@/hooks/useCheckWelloApp';

import { MainFooter } from './_components/MainFooter';
import styles from './page.module.scss';

const HomeLayout = ({ children }: PropsWithChildren) => {
  const { isWelloApp } = useCheckWelloApp();

  return (
    <article>
      <PageNavigation
        logo
        searchButton
        backButton={false}
        bellButton={isWelloApp}
        className={styles['page-navigation']}
      />
      {children}
      <MainFooter />
    </article>
  );
};

export default HomeLayout;
